import { ReactNode } from 'react';
import { BaseModel } from 'sjs-base-model';
import PartnerProductCategoryModel from './PartnerProductCategory';

export default class PartnerProductModel extends BaseModel {
  [x: string]: ReactNode;
  public readonly id: string = '';
  public readonly name: string = '';
  public readonly name_en: string = '';
  public readonly context: string = '';
  public readonly context_en: string = '';
  public readonly product_medias: [] = [];
  public readonly category_name: string = '';
  public readonly category_name_en: string = '';
  public readonly product_category: PartnerProductCategoryModel = PartnerProductCategoryModel as any;

  constructor(data: Partial<PartnerProductModel>) {
    super();
    this.update(data);
  }
}
