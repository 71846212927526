/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/camelcase */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory, History } from 'history';
import IStore from './models/IStore';
import rootStore from './stores/rootStore';
import App from './views/App';
import environment from 'environment';

import '../src/utilities/i18n';

(async (window: Window): Promise<void> => {
  const initialState: Partial<IStore> = {};
  const history: History = createBrowserHistory({ basename: environment.route.baseRoute });
  const store: Store<IStore> = rootStore(initialState, history);

  const rootEl: HTMLElement | null = document.getElementById('root');
  const render = (Component: typeof App, el: HTMLElement | null): void => {
    ReactDOM.render(
      <Provider store={store}>
        {/* <Snowfall /> */}
        <Component history={history} dispatch={store.dispatch} />

      </Provider>,
      el
    );
  };

  (window as any).erxesSettings = {
    messenger: {
      brand_id: 'XHhuJp',
    },
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  (() => {
    const script = document.createElement('script');
    script.src = 'https://erxes.tavanbogd.mn/widgets/build/messengerWidget.bundle.js';
    script.async = true;

    const entry = document.getElementsByTagName('script')[0];
    if (entry.parentNode) {
      entry.parentNode.insertBefore(script, entry);
    }
  })();
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    // eslint-disable-next-line no-var
    var f = d.getElementsByTagName('script')[0],
      j = d.createElement('script'),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    if (f.parentNode) {
      f.parentNode.insertBefore(j, f);
    }
  })(window, document, 'script', 'dataLayer', 'GTM-5S9BBSP');
  render(App, rootEl);
})(window);


// function Snowfall() {
//   function snow() {
//     let animationDelay = '0s';
//     let fontSize = '100px';
//     let arr = Array.from('Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!!')
//     return arr.map((el, i) => {
//       animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
//       fontSize = `${(Math.floor(Math.random() * 10) + 10)}px`;
//       let style = {
//         animationDelay,
//         fontSize
//       }
//       return (<p className='Snowflake' style={style}>
//         ❄
//       </p>)
//     })
//   }


//   return (
//     <div className='App'>
//       {snow()}
//     </div>
//   )
// }

