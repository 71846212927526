import { BaseModel } from 'sjs-base-model';

export default class ProductListModel extends BaseModel {
  public readonly id: string = '';
  public readonly name: string = '';
  public readonly name_en: string = '';
  public readonly product_medias: [] = [];
  public readonly product_category: [] = [];
  public readonly company: any;
  constructor(data: Partial<ProductListModel>) {
    super();
    this.update(data);
  }
}
