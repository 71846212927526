import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as HttpUtility from '../../utilities/HttpUtility';
import CategoryListModel from './models/solutions/CategoryListModel';
import SolutionsListModel from './models/solutions/SolutionsListModel';
// productModel
// serviceModel
// solutionModel
import { AxiosResponse } from 'axios';
import ProductListModel from './models/solutions/ProductsListModel';

export async function requestSolutions(solid: string): Promise<SolutionsListModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.SolutionsList.replace(':id', solid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<SolutionsListModel>) => new SolutionsListModel(json));
}

export async function requestCategoryList(): Promise<CategoryListModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.CategoryList;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<CategoryListModel>) => new CategoryListModel(json));
}

export async function requestProducts(solid: string): Promise<ProductListModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.SolutionsProductsList.replace(':id', solid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<ProductListModel>) => new ProductListModel(json));
}
