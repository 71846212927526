import ICompaniesState from './models/ICompaniesState';
import * as CompaniesAction from './CompaniesAction';
import IAction from '../../models/IAction';
import InfoModel from './models/companies/InfoModel';
// product
// service
// solution
import baseReducer from '../../utilities/BaseReducer';
import { Reducer } from 'redux';
import ListModel from './models/companies/ListModel';

export const initialState: ICompaniesState = {
  list: null,
  info: null,
  // product []
  // service []
  // solution []
};

const companiesReducer: Reducer = baseReducer(initialState, {
  [CompaniesAction.REQUEST_INFO_FINISHED](state: ICompaniesState, action: IAction<InfoModel>): ICompaniesState {
    return {
      ...state,
      info: action.payload!,
    };
  },
  [CompaniesAction.REQUEST_COMPANY_LIST_FINISHED](state: ICompaniesState, action: IAction<ListModel[]>): ICompaniesState {
    return {
      ...state,
      list: action.payload!,
    };
  },
  // product
  // service
  // solution
});

export default companiesReducer;
