import ISolutionsState from './models/ISolutionsState';
import * as SolutionsAction from './SolutionsAction';
import IAction from '../../models/IAction';
import CategoryListModel from './models/solutions/CategoryListModel';
import SolutionsListModel from './models/solutions/SolutionsListModel';
import ProductsListModel from './models/solutions/ProductsListModel';
// product
// service
// solution
import baseReducer from '../../utilities/BaseReducer';
import { Reducer } from 'redux';

export const initialState: ISolutionsState = {
  CateList: null,
  SolutionsListHome: null,
  ProductsList: null,
};

const companiesReducer: Reducer = baseReducer(initialState, {
  [SolutionsAction.REQUEST_SOLUTIONS_FINISHED](state: ISolutionsState, action: IAction<SolutionsListModel[]>): ISolutionsState {
    return {
      ...state,
      SolutionsListHome: action.payload!,
    };
  },
  [SolutionsAction.REQUEST_CATEGORY_FINISHED](state: ISolutionsState, action: IAction<CategoryListModel[]>): ISolutionsState {
    return {
      ...state,
      CateList: action.payload!,
    };
  },
  [SolutionsAction.REQUEST_PRODUCTS_FINISHED](state: ISolutionsState, action: IAction<ProductsListModel[]>): ISolutionsState {
    return {
      ...state,
      ProductsList: action.payload!,
    };
  },
});

export default companiesReducer;
