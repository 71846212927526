import { BaseModel } from 'sjs-base-model';
// import CompanyModel from './CompanyModel';
/*
  // Returned Api data sample
    {
      "id"  : 1,
      "title" : "XEROX news",
      "date"  : "2020.03.03",
      "brand" : "xerox",
      "context" : "blablablalb aslbadflbadfl asl;d asdl fasl dflasldf ladsflasld fldasl ",
      "img"   : "https://www.xerox.mn/userFiles/images/2020/03/19/1584589952.5702.PNG"
    }
 */

export default class NewsModel extends BaseModel {
  public readonly id: string = '';
  public readonly title: string = '';
  public readonly title_en: string = '';
  public readonly context: string = '';
  public readonly context_en: string = '';
  public readonly published_date: string = '';
  public readonly company: any = {};
  public readonly tags: [] = [];
  public readonly media_path: string = '';
  public readonly content: string = '';
  public readonly content_en: string = '';

  constructor(data: Partial<NewsModel>) {
    super();

    this.update(data);
  }
}
