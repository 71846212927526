import { BaseModel } from 'sjs-base-model';

export default class PartnerModel extends BaseModel {
  public readonly id: string = '';
  public readonly name: string = '';
  public readonly name_en: string = '';
  public readonly introduction: string = '';
  public readonly introduction_en: string = '';
  public readonly media_path: string = '';
  public readonly media_ext: string = '';

  constructor(data: Partial<PartnerModel>) {
    super();
    this.update(data);
  }
}
