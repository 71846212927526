import * as SolutionEffect from './SolutionsEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as ActionUtility from '../../utilities/ActionUtility';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import CategoryListModel from './models/solutions/CategoryListModel';
import SolutionsListModel from './models/solutions/SolutionsListModel';

import ProductListModel from './models/solutions/ProductsListModel';

type ActionUnion = undefined | HttpErrorResponseModel | CategoryListModel[] | SolutionsListModel[];

export const REQUEST_CATEGORY: string = 'SolutionsAction.REQUEST_CATEGORY';
export const REQUEST_CATEGORY_FINISHED: string = 'SolutionsAction.REQUEST_CATEGORY_FINISHED';

export function requestCategoryList(): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    ActionUtility.createThunkEffect<CategoryListModel[]>(dispatch, REQUEST_CATEGORY, SolutionEffect.requestCategoryList);
  };
}

export const REQUEST_SOLUTIONS: string = 'SolutionsAction.REQUEST_SOLUTIONS';
export const REQUEST_SOLUTIONS_FINISHED: string = 'SolutionsAction.REQUEST_SOLUTIONS_FINISHED';

export function requestSolutionsList(id: any): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<SolutionsListModel[]>(dispatch, REQUEST_SOLUTIONS, SolutionEffect.requestSolutions, id);
  };
}

export const REQUEST_PRODUCTS: string = 'SolutionsAction.REQUEST_PRODUCTS';
export const REQUEST_PRODUCTS_FINISHED: string = 'SolutionsAction.REQUEST_PRODUCTS_FINISHED';

export function requestProductsList(id: any): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<ProductListModel[]>(dispatch, REQUEST_PRODUCTS, SolutionEffect.requestProducts, id);
  };
}
