enum RouteEnum {
  BaseCompanyId = '1',
  Base = 'https://core.tbsolutions.mn/',
  //Base = 'http://192.168.9.32:3101/',
  BaseHome = 'https://beta.tbsolutions.mn/',
  DefHR = 'http://anket.tavanbogd.mn:8081/',
  Home = '/',
  News = '/news',
  ShowNews = '/news/:id',
  Client = '/client',
  HR = '/hr',
  About = '/about',
  Contact = '/contact',
  Application = '/hr/application',
  Partner = '/partner/about/:id/Partner',
  PartnerSolutions = '/partner/solutions/:id',
  PartnerSupports = '/partner/supports/:id',
  PartnerProducts = '/partner/products/:id',
  PartnerProductsMore = '/partner/products/:id/:Pid/:name',
  Episodes = '/episodes',
}

export default RouteEnum;
