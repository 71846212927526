import INewsState from './models/INewsState';
import * as NewsAction from './NewsAction';
import IAction from '../../models/IAction';
import baseReducer from '../../utilities/BaseReducer';
import { Reducer } from 'redux';
import NewsModel from './models/NewsModel';

export const initialState: INewsState = {
  newses: [],
};

const NewsReducer: Reducer = baseReducer(initialState, {
  [NewsAction.REQUEST_NEWS_FINISHED](state: INewsState, action: IAction<NewsModel[]>): INewsState {
    return {
      ...state,
      newses: action.payload!,
    };
  },
});

export default NewsReducer;
