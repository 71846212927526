/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(baseApi: string) {
  return {
    route: {
      baseRoute: '/', // Fixes issue with Github Pages
    },
    api: {
      companyList: `${baseApi}company/list`,
      partner: `${baseApi}company/info/:id`,
      partnerProductCount: `${baseApi}product/count/:id`,
      partnerSlider: `${baseApi}company/slider/:id`,
      partnerService: `${baseApi}company/services/:id`,
      partnerSolutions: `${baseApi}company/solutions/brand/:id`,
      partnerProducts: `${baseApi}product/:id`,
      partnerCurrentProduct: `${baseApi}product/current/:id`,
      news: `${baseApi}news`,
      info: `${baseApi}company/info/:id`,

      contacts: `${baseApi}contacts`,

      cast: `${baseApi}shows/:showId/cast`,
      episodes: `${baseApi}shows/:showId/episodes`,
      shows: `${baseApi}shows/:showId`,
      errorExample: 'https://httpstat.us/520',
      //uuganaa
      //192.168.9.32:3101/company/solution/category
      CategoryList: `${baseApi}company/solution/category`,
      //ed143761-3999-499d-bd35-38ea4cff3de2
      SolutionsList: `${baseApi}company/solutions/:id`,
      SolutionsProductsList: `${baseApi}product/solution/:id`,
    },
    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  };
}

export type Environment = ReturnType<typeof baseEnv>;
