/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styles from './LoadingIndicator.module.scss';

import React from 'react';
import classNames from 'classnames';
import RingLoader from 'react-spinners/RingLoader';
import { css } from '@emotion/react';

interface IProps {
  readonly isActive?: boolean;
  readonly className?: string;
}
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function LoadingIndicator(props: React.PropsWithChildren<IProps>): React.FunctionComponentElement<IProps> {
  const { isActive = false } = props; // defaultProps example
  const { className, children } = props;
  const color = '#FFFFFF ';

  const cssClasses: string = classNames(className, {
    [styles.wrapper]: isActive,
  });

  return (
    <div className={cssClasses}>
      {isActive && (
        <div className={styles.loaderContainer}>
          <div className="RingLoader">
            <RingLoader color={color} loading={true} size={50} />
          </div>
        </div>
      )}
      {children}
    </div>
  );
}
