import React, { Suspense, lazy } from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAction from '../models/IAction';
import RouteEnum from '../constants/RouteEnum';
// import BrandsEnum from 'constants/BrandsEnum';
import LoadingIndicator from './components/loading-indicator/LoadingIndicator';
import Toasts from './components/toasts/Toasts';

const HomePage = lazy(() => import('./home-page/HomePage'));
const NotFoundPage = lazy(() => import('./not-found-page/NotFoundPage'));
const NewsPage = lazy(() => import('./news-page/NewsPage'));
const ShowNewsPage = lazy(() => import('./news-page/ShowNewsPage'));
const ClientPage = lazy(() => import('./client-page/ClientPage'));
const AboutPage = lazy(() => import('./about-page/AboutPage'));
const ContactPage = lazy(() => import('./contact-page/ContactPage'));
const PartnerPage = lazy(() => import('views/partners-page/PartnersPage'));
const PartnerSolutionsPage = lazy(() => import('views/partners-page/SolutionsPage'));
const PartnerProductsPage = lazy(() => import('views/partners-page/ProductsPage'));
const PartnerSupportsPage = lazy(() => import('views/partners-page/SupportsPage'));
const PartnerProductsMorePage = lazy(() => import('views/partners-page/ProductsMore'));
interface IProps {
  readonly history: History;
  readonly dispatch: Dispatch<IAction<any>>;
}

export default function App(props: React.PropsWithChildren<IProps>): JSX.Element {
  return (
    <ConnectedRouter history={props.history}>
      <Suspense fallback={<LoadingIndicator isActive={true} />}>
        <Switch>
          <Route exact={true} path={RouteEnum.Home} component={HomePage} />
          <Route exact={true} path={RouteEnum.News} component={NewsPage} />
          <Route path={RouteEnum.ShowNews} component={ShowNewsPage} />
          <Route path={RouteEnum.Client} component={ClientPage} />
          <Route path={RouteEnum.About} component={AboutPage} />
          <Route path={RouteEnum.Contact} component={ContactPage} />
          <Route exact={true} path={RouteEnum.Partner} component={PartnerPage} />
          <Route path={RouteEnum.PartnerSolutions} component={PartnerSolutionsPage} />
          <Route exact={true} path={RouteEnum.PartnerProducts} component={PartnerProductsPage} />
          <Route path={RouteEnum.PartnerSupports} component={PartnerSupportsPage} />
          <Route path={RouteEnum.PartnerProductsMore} component={PartnerProductsMorePage} />
          <Route component={NotFoundPage} />
        </Switch>
        <Toasts />
      </Suspense>
    </ConnectedRouter>
  );
}
