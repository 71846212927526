import { BaseModel } from 'sjs-base-model';

export default class ListModel extends BaseModel {
  public readonly id: string = '';
  public readonly name: string = '';

  constructor(data: Partial<ListModel>) {
    super();
    this.update(data);
  }
}
