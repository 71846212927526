import { BaseModel } from 'sjs-base-model';

export default class PartnerServiceModel extends BaseModel {
  public readonly title: string = '';
  public readonly title_en: string = '';
  public readonly context: string = '';
  public readonly context_en: string = '';
  public readonly img_path: string = '';

  constructor(data: Partial<PartnerServiceModel>) {
    super();
    this.update(data);
  }
}
