import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as HttpUtility from '../../utilities/HttpUtility';
import { AxiosResponse } from 'axios';
// import * as EffectUtility from '../../utilities/EffectUtility';
import NewsModel from './models/NewsModel';

export async function requestNews(): Promise<NewsModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.news;

  // const response: any = [
  //   {
  //     id: 1,
  //     title: 'XEROX news',
  //     date: '2020.03.03',
  //     brand: 'xerox',
  //     context: 'blablablalb aslbadflbadfl asl;d asdl fasl dflasldf ladsflasld fldasl ',
  //     img: 'https://www.xerox.mn/userFiles/images/2020/03/19/1584589952.5702.PNG',
  //   },
  //   {
  //     id: 2,
  //     title: 'AXIS news',
  //     date: '2020.03.05',
  //     brand: 'axis',
  //     context: 'asdf as kfja lsjfdjl a lja sjkf a;slfk a;slk ;asld ',
  //     img: 'https://khamaylclub.com/wp-content/uploads/2019/07/basketball-featured-img-450-300.jpg',
  //   },
  // ];
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map((json: Partial<NewsModel>) => new NewsModel(json));
}
