import { BaseModel } from 'sjs-base-model';

export default class InfoModel extends BaseModel {
  public readonly id: string = '';
  public readonly name: string = '';
  public readonly name_en: string = '';
  public readonly introduction: string = '';
  public readonly introduction_en: string = '';
  public readonly media_path: string = '';
  public readonly mission: string = '';
  public readonly mission_en: string = '';
  public readonly activities: string = '';
  public readonly activities_en: string = '';
  public readonly vision: string = '';
  public readonly vision_en: string = '';
  public readonly address: string = '';
  public readonly address_en: string = '';
  public readonly timetable: string = '';
  public readonly timetable_en: string = '';
  public readonly email: string = '';
  public readonly phone_number: number = 0;
  public readonly security_context: string = '';
  public readonly security_context_en: string = '';
  public readonly socials: [] = [];

  constructor(data: Partial<InfoModel>) {
    super();
    this.update(data);
  }
}
