import * as PartnerEffect from './PartnerEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as ActionUtility from '../../utilities/ActionUtility';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import PartnerModel from './models/PartnerModel';
import PartnerProductCountModel from './models/PartnerProductCountModel';
import PartnerSliderModel from './models/PartnerSliderModel';
import PartnerServiceModel from './models/PartnerServiceModel';
import PartnerSolutionsModel from './models/PartnerSolutionsModel';
import PartnerProductModel from './models/PartnerProductModel';
import ProductModel from './models/ProductModel';

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | PartnerModel
  | PartnerProductCountModel
  | PartnerSliderModel[]
  | PartnerServiceModel[]
  | PartnerSolutionsModel[]
  | PartnerProductModel[]
  | ProductModel;

export const REQUEST_PARTNER: string = 'PartnerAction.REQUEST_PARTNER';
export const REQUEST_PARTNER_FINISHED: string = 'PartnerAction.REQUEST_PARTNER_FINISHED';

export function requestPartner(id: string): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<PartnerModel>(dispatch, REQUEST_PARTNER, PartnerEffect.requestPartner, id);
  };
}

export const REQUEST_PARTNER_PRODUCT_COUNT: string = 'PartnerAction.REQUEST_PARTNER_PRODUCT_COUNT';
export const REQUEST_PARTNER_PRODUCT_COUNT_FINISHED: string = 'PartnerAction.REQUEST_PARTNER_PRODUCT_COUNT_FINISHED';

export function requestPartnerProductCount(id: string): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    ActionUtility.createThunkEffect<PartnerProductCountModel>(dispatch, REQUEST_PARTNER_PRODUCT_COUNT, PartnerEffect.requestPartnerProductCount, id);
  };
}

export const REQUEST_PARTNER_SLIDER: string = 'PartnerAction.REQUEST_PARTNER_SLIDER';
export const REQUEST_PARTNER_SLIDER_FINISHED: string = 'PartnerAction.REQUEST_PARTNER_SLIDER_FINISHED';

export function requestPartnerSlider(id: string): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<PartnerSliderModel[]>(dispatch, REQUEST_PARTNER_SLIDER, PartnerEffect.requestPartnerSlider, id);
  };
}

export const REQUEST_PARTNER_SERVICE: string = 'PartnerAction.REQUEST_PARTNER_SERVICE';
export const REQUEST_PARTNER_SERVICE_FINISHED: string = 'PartnerAction.REQUEST_PARTNER_SERVICE_FINISHED';

export function requestPartnerService(id: string): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<PartnerServiceModel[]>(dispatch, REQUEST_PARTNER_SERVICE, PartnerEffect.requestPartnerService, id);
  };
}

export const REQUEST_PARTNER_SOLUTIONS: string = 'PartnerAction.REQUEST_PARTNER_SOLUTIONS';
export const REQUEST_PARTNER_SOLUTIONS_FINISHED: string = 'PartnerAction.REQUEST_PARTNER_SOLUTIONS_FINISHED';

export function requestpartnerSolutions(id: string): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<PartnerSolutionsModel[]>(dispatch, REQUEST_PARTNER_SOLUTIONS, PartnerEffect.requestPartnerSolutions, id);
  };
}

export const REQUEST_PARTNER_PRODUCTS: string = 'PartnerAction.REQUEST_PARTNER_PRODUCTS';
export const REQUEST_PARTNER_PRODUCTS_FINISHED: string = 'PartnerAction.REQUEST_PARTNER_PRODUCTS_FINISHED';

export function requestPartnerProducts(id: string): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<PartnerProductModel[]>(dispatch, REQUEST_PARTNER_PRODUCTS, PartnerEffect.requestPartnerProducts, id);
  };
}

export const REQUEST_PARTNER_CURRENT_PRODUCT: string = 'PartnerAction.REQUEST_PARTNER_CURRENT_PRODUCT';
export const REQUEST_PARTNER_CURRENT_PRODUCT_FINISHED: string = 'PartnerAction.REQUEST_PARTNER_CURRENT_PRODUCT_FINISHED';

export function requestPartnerCurrentProduct(id: string): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<ProductModel>(dispatch, REQUEST_PARTNER_CURRENT_PRODUCT, PartnerEffect.requestPartnerCurrentProduct, id);
  };
}
