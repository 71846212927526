import IPartnerState from './models/IPartnerState';
import * as PartnerAction from './PartnerAction';
import IAction from '../../models/IAction';
import PartnerModel from './models/PartnerModel';
import baseReducer from '../../utilities/BaseReducer';
import { Reducer } from 'redux';
import PartnerProductCountModel from './models/PartnerProductCountModel';
import PartnerSliderModel from './models/PartnerSliderModel';
import PartnerServiceModel from './models/PartnerServiceModel';
import PartnerSolutionsModel from './models/PartnerSolutionsModel';
import PartnerProductModel from './models/PartnerProductModel';
import ProductModel from './models/ProductModel';

export const initialState: IPartnerState = {
  partner: null,
  productCount: 0,
  sliders: [],
  services: [],
  solutions: [],
  products: [],
  currentProduct: null,
};

const partnerReducer: Reducer = baseReducer(initialState, {
  [PartnerAction.REQUEST_PARTNER_FINISHED](state: IPartnerState, action: IAction<PartnerModel>): IPartnerState {
    return {
      ...state,
      partner: action.payload!,
    };
  },
  [PartnerAction.REQUEST_PARTNER_PRODUCT_COUNT_FINISHED](state: IPartnerState, action: IAction<PartnerProductCountModel>): IPartnerState {
    return {
      ...state,
      productCount: action.payload!,
    };
  },
  [PartnerAction.REQUEST_PARTNER_SLIDER_FINISHED](state: IPartnerState, action: IAction<PartnerSliderModel[]>): IPartnerState {
    return {
      ...state,
      sliders: action.payload!,
    };
  },
  [PartnerAction.REQUEST_PARTNER_SERVICE_FINISHED](state: IPartnerState, action: IAction<PartnerServiceModel[]>): IPartnerState {
    return {
      ...state,
      services: action.payload!,
    };
  },
  [PartnerAction.REQUEST_PARTNER_SOLUTIONS_FINISHED](state: IPartnerState, action: IAction<PartnerSolutionsModel[]>): IPartnerState {
    return {
      ...state,
      solutions: action.payload!,
    };
  },
  [PartnerAction.REQUEST_PARTNER_PRODUCTS_FINISHED](state: IPartnerState, action: IAction<PartnerProductModel[]>): IPartnerState {
    return {
      ...state,
      products: action.payload!,
    };
  },
  [PartnerAction.REQUEST_PARTNER_CURRENT_PRODUCT_FINISHED](state: IPartnerState, action: IAction<ProductModel>): IPartnerState {
    return {
      ...state,
      currentProduct: action.payload!,
    };
  },
});

export default partnerReducer;
