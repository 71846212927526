import * as CompaniesEffect from './CompaniesEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as ActionUtility from '../../utilities/ActionUtility';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import InfoModel from './models/companies/InfoModel';
import ListModel from './models/companies/ListModel';
import RouteEnum from 'constants/RouteEnum';
// productModel
// serviceModel
// solutionModel

type ActionUnion = undefined | HttpErrorResponseModel | InfoModel | ListModel[]; // product[], service[], solution[]

export const REQUEST_INFO: string = 'CompaniesAction.REQUEST_INFO';
export const REQUEST_INFO_FINISHED: string = 'CompaniesAction.REQUEST_INFO_FINISHED';

export function requestInfo(): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const companyId: string = RouteEnum.BaseCompanyId;

    await ActionUtility.createThunkEffect<InfoModel>(dispatch, REQUEST_INFO, CompaniesEffect.requestInfo, companyId);
  };
}

export const REQUEST_COMPANY_LIST: string = 'CompaniesAction.REQUEST_COMPANY_LIST';
export const REQUEST_COMPANY_LIST_FINISHED: string = 'CompaniesAction.REQUEST_COMPANY_LIST_FINISHED';

export function requestCompanyList(): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<ListModel[]>(dispatch, REQUEST_COMPANY_LIST, CompaniesEffect.requestCompanyList);
  };
}
