import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as HttpUtility from '../../utilities/HttpUtility';
import InfoModel from './models/companies/InfoModel';
// productModel
// serviceModel
// solutionModel
import { AxiosResponse } from 'axios';
import * as EffectUtility from '../../utilities/EffectUtility';
import ListModel from './models/companies/ListModel';

export async function requestInfo(companyId: string): Promise<InfoModel | HttpErrorResponseModel> {
  const endpoint: string = environment.api.info.replace(':id', companyId);

  return EffectUtility.getToModel<InfoModel>(InfoModel, endpoint);
}

export async function requestCompanyList(): Promise<ListModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.companyList;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<ListModel>) => new ListModel(json));
}
