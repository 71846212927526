import { BaseModel } from 'sjs-base-model';

export default class SolutionsListModel extends BaseModel {
  public readonly id: string = '';
  public readonly title: string = '';
  public readonly title_en: string = '';
  public readonly context: string = '';
  public readonly context_en: string = '';
  public readonly tags: [] = [];
  public readonly companies: [] = [];
  constructor(data: Partial<SolutionsListModel>) {
    super();
    this.update(data);
  }
}
