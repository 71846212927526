import * as NewsEffect from './NewsEffect';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as ActionUtility from '../../utilities/ActionUtility';
import { ReduxDispatch } from '../../models/ReduxProps';
import IStore from '../../models/IStore';
import NewsModel from './models/NewsModel';

type ActionUnion = undefined | HttpErrorResponseModel | NewsModel[];

export const REQUEST_NEWS: string = 'NewsAction.REQUEST_NEWS';
export const REQUEST_NEWS_FINISHED: string = 'NewsAction.REQUEST_NEWS_FINISHED';

export function requestNews(): any {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<NewsModel[]>(dispatch, REQUEST_NEWS, NewsEffect.requestNews);
  };
}
