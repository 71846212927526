import environment from 'environment';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import * as HttpUtility from '../../utilities/HttpUtility';
import { AxiosResponse } from 'axios';
import * as EffectUtility from '../../utilities/EffectUtility';
import PartnerModel from './models/PartnerModel';
import PartnerProductCountModel from './models/PartnerProductCountModel';
import PartnerSliderModel from './models/PartnerSliderModel';
import PartnerServiceModel from './models/PartnerServiceModel';
import PartnerSolutionsModel from './models/PartnerSolutionsModel';
import PartnerProductModel from './models/PartnerProductModel';

export async function requestPartner(id: string): Promise<PartnerModel | HttpErrorResponseModel> {
  const endpoint: string = environment.api.partner.replace(':id', id);
  return EffectUtility.getToModel<PartnerModel>(PartnerModel, endpoint);
}

export async function requestPartnerProductCount(id: string): Promise<PartnerProductCountModel | HttpErrorResponseModel> {
  const endpoint: string = environment.api.partnerProductCount.replace(':id', id);
  const res = await EffectUtility.getToModel<PartnerProductCountModel>(PartnerProductCountModel, endpoint);
  return res;
}

export async function requestPartnerSlider(id: string): Promise<PartnerSliderModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.partnerSlider.replace(':id', id);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<PartnerSliderModel>) => new PartnerSliderModel(json));
}

export async function requestPartnerService(id: string): Promise<PartnerServiceModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.partnerService.replace(':id', id);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<PartnerServiceModel>) => new PartnerServiceModel(json));
}

export async function requestPartnerSolutions(id: string): Promise<PartnerSolutionsModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.partnerSolutions.replace(':id', id);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<PartnerSolutionsModel>) => new PartnerSolutionsModel(json));
}

export async function requestPartnerProducts(id: string): Promise<PartnerProductModel[] | HttpErrorResponseModel> {
  const endpoint: string = environment.api.partnerProducts.replace(':id', id);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<PartnerProductModel>) => new PartnerProductModel(json));
}

export async function requestPartnerCurrentProduct(id: string): Promise<any | HttpErrorResponseModel> {
  const endpoint: string = environment.api.partnerCurrentProduct.replace(':id', id);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  return response;
  // return EffectUtility.getToModel<ProductModel>(ProductModel, endpoint);
}
