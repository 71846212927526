import { BaseModel } from 'sjs-base-model';

export default class PartnerSolutionsModel extends BaseModel {
  public readonly title: string = '';
  public readonly title_en: string = '';
  public readonly context: string = '';
  public readonly context_en: string = '';
  public readonly solution_medias: [] = [];

  constructor(data: Partial<PartnerSolutionsModel>) {
    super();
    this.update(data);
  }
}
